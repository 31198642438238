<template>
    <v-container
        fluid
        grid-list-xl
    >
        <v-dialog
            v-model="descriptor.show"
        >
            <v-card>
                <v-card-title primary-title>
                    <div>
                        <h3 class="headline">
                            {{ descriptor.flow.name | defaultName }}
                        </h3><br>
                        <div>Flow ID: {{ descriptor.flow.flowId }}</div>
                    </div>
                </v-card-title>

                <v-card-text>
                    <pre>{{ descriptor.flow | prettyFlowDescriptor }}</pre>
                </v-card-text>

                <v-divider />

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="green darken-1"
                        text
                        @click="descriptor.show = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="thumbnail.show"
        >
            <v-card>
                <v-card-text>
                    <img :src="thumbnail.src">
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="green darken-1"
                        text
                        @click="thumbnail.show = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="telemetry.show"
            max-width="500"
        >
            <v-card>
                <v-card-title>
                    <p class="display-1 text--primary text-center">
                        "{{ telemetry.flowName | defaultName }}" metrics
                    </p>
                </v-card-title>

                <v-container
                    fluid
                    grid-list-lg
                    class="no-padding"
                >
                    <v-layout row>
                        <v-flex xs6>
                            <v-card-text v-if="!telemetry.loading">
                                <div>Number of Messages:</div>
                                <p class="display-1 text--primary text-center">
                                    {{ telemetry.result.totalCount }}
                                </p>
                            </v-card-text>
                        </v-flex>
                        <v-flex xs6>
                            <v-card-text>
                                <div>Messages Size:</div>
                                <p class="display-1 text--primary text-center">
                                    {{ telemetry.result.totalSize | formatBytes(2) }}
                                </p>
                            </v-card-text>
                        </v-flex>
                    </v-layout>
                </v-container>

                <spinner v-if="telemetry.loading" />

                <v-divider />

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="green darken-1"
                        text
                        @click="telemetry.show = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-layout
            justify-center
            wrap
        >
            <v-flex
                md12
                class="filter-panel"
            >
                <v-layout>
                    <v-flex md6>
                        <v-text-field
                            v-model="search.searchString.current"
                            label="Search"
                        />

                        <v-select
                            v-model="search.stage.value"
                            :items="search.stage.options"
                            label="Stage"
                            @change="refreshFlows()"
                        />
                    </v-flex>

                    <v-flex md6>
                        <v-select
                            v-model="search.searchBy.value"
                            :items="search.searchBy.options"
                            label="Search criteria"
                            @change="refreshFlows()"
                        />
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-flex md12>
                <material-card
                    :title="'Flows (' + total + ')'"
                    color="tertiary"
                >
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        :loading-data="search.loading"
                        hide-actions
                    >
                        <template slot="no-data">
                            <div
                                class="text-xs-center"
                                style="width: 100%"
                            >
                                No data available
                            </div>
                        </template>

                        <template
                            slot="headerCell"
                            slot-scope="{ header }"
                        >
                            <span
                                class="subheading font-weight-bold text--darken-3"
                                v-text="header.text"
                            />
                        </template>
                        <template
                            slot="items"
                            slot-scope="{ item }"
                        >
                            <td><span>{{ item.name | defaultName }}</span></td>
                            <td>{{ item.stage }}</td>
                            <td>{{ item.type }}</td>
                            <td>
                                <a :href="`/dashboard/users?username-query=${item.user.username}`">{{
                                    item.user.username
                                }}</a>
                            </td>
                            <td>{{ item.btime | formatDate('DD MMMM YYYY HH:mm:ss') }}</td>
                            <td>{{ item.mtime | formatDate('DD MMMM YYYY HH:mm:ss') }}</td>
                            <td>{{ item.flow | componentsCount }}</td>

                            <td>
                                <img
                                    :src="item.thumbnail"
                                    class="thumbnail pointer"
                                    @click="showThumbnail(item.thumbnail)"
                                >
                            </td>
                            <td>
                                <a
                                    class="action-btn"
                                    @click="showDescriptor(item)"
                                >Descriptor</a><br>
                                <a
                                    class="action-btn"
                                    target="_blank"
                                    :href="`${appmixerStudioUrl}/insights/logs/${item.flowId}`"
                                >Logs
                                    <v-icon size="small">mdi-open-in-new</v-icon>
                                </a><br>
                                <a
                                    class="action-btn"
                                    target="_blank"
                                    :href="`${appmixerStudioUrl}/designer/${item.flowId}`"
                                >Flow
                                    <v-icon size="small">mdi-open-in-new</v-icon>
                                </a><br>
                                <a
                                    class="action-btn"
                                    @click="showTelemetry(item)"
                                >Metrics</a>
                            </td>
                        </template>
                    </v-data-table>
                </material-card>

                <v-layout justify-center>
                    <v-pagination
                        v-if="total"
                        v-model="search.page"
                        :length="paginatorLength"
                        :total-visible="6"
                        @previous="getFlows()"
                        @next="getFlows()"
                        @input="getFlows()"
                    />
                </v-layout>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import FlowsMixin from '../../mixins/flows';
import QueryUtils from '../../utils/query';
import UserRequests from '../../utils/requests/user';
import StatusRequests from '../../utils/requests/status';
import Spinner from '../../components/common/Spinner';
import _ from 'lodash';
import IndexQueries from '../../utils/requests/dataIndex';
import Joi from 'joi';
import appmixer from '@/plugins/appmixer';

function validateFlow(flow) {
    const schema = Joi.object({
        btime: Joi.string().required(),
        mtime: Joi.string().required(),
        flow: Joi.object().required()
    }).unknown(true);
    const result = schema.validate(flow);
    return !result.error;
}

export default {
    name: 'Flows',
    components: { Spinner },
    metaInfo() {
        return {
            title: 'Appmixer Backoffice - Flows'
        };
    },
    filters: {
        prettyFlowDescriptor(descriptor) {
            return JSON.stringify(descriptor, null, '\t');
        },
        componentsCount(descriptor) {
            return Object.keys(descriptor).length;
        },
        defaultName(name) {
            if (name) {
                return name;
            }
            return 'Default Name';
        }
    },
    mixins: [FlowsMixin],
    data() {
        return {
            // Default, will be replaced once studioUrl is loaded.
            appmixerStudioUrl: appmixer.api.baseUrl.replace('api.', 'my.'),
            headers: [
                {
                    sortable: false,
                    text: 'Name',
                    value: 'name'
                },
                {
                    sortable: false,
                    text: 'Stage',
                    value: 'stage'
                },
                {
                    sortable: false,
                    text: 'Type',
                    value: 'type'
                },
                {
                    sortable: false,
                    text: 'Username',
                    value: 'user'
                },
                {
                    sortable: false,
                    text: 'Created At',
                    value: 'created'
                },
                {
                    sortable: false,
                    text: 'Modified At',
                    value: 'modified'
                },
                {
                    sortable: false,
                    text: 'Components',
                    value: 'components'
                },
                {
                    sortable: false,
                    text: 'Thumbnail',
                    value: 'thumbnail'
                },
                {
                    sortable: false,
                    text: 'Actions',
                    value: ''
                }
            ],
            items: [],
            total: null,
            descriptor: {
                show: false,
                flow: {}
            },
            thumbnail: {
                show: false,
                src: ''
            },
            telemetry: {
                show: false,
                loading: false,
                result: {},
                flowId: '',
                flowName: ''
            },
            search: {
                loading: false,
                page: 1,
                pageSize: QueryUtils.DEFAULT_PAGE_SIZE,

                searchString: { old: '', current: '' },
                searchBy: {
                    value: 'flowName',
                    options: [
                        { text: 'Search by Flow Name', value: 'flowName' },
                        { text: 'Search by Flow ID', value: 'flowId' },
                        { text: 'Search by Username', value: 'username' },
                        { text: 'Search by User ID', value: 'userId' },
                        { text: 'Search by Module/Component usage', value: 'module' }
                    ]
                },
                stage: {
                    value: null,
                    options: [
                        { text: 'All', value: null },
                        { text: 'Stopped', value: 'stopped' },
                        { text: 'Running', value: 'running' }
                    ]
                }
            },
            debouncedChangedSearch: _.debounce(this.changedSearchString, 700)
        };
    },

    computed: {
        paginatorLength() {
            return this.total ? Math.ceil(this.total / this.search.pageSize) : 0;
        }
    },

    watch: {
        'search.searchString.current': 'debouncedChangedSearch'
    },

    async created() {
        const componentQuery = this.$route.query['module-query'];
        const userIdQuery = this.$route.query['userId-query'];
        if (componentQuery) {
            const [component, type] = componentQuery.split(':');
            this.search.searchBy.value = 'module';
            this.search.searchString.current = component;
            if (type) {
                this.search.stage.value = type;
            }
        }
        if (userIdQuery) {
            this.search.searchBy.value = 'userId';
            this.search.searchString.current = userIdQuery;
        }
        this.refreshFlows();
        const status = await StatusRequests.getStatus();
        if (status.studioUrl) {
            this.appmixerStudioUrl = status.studioUrl;
        } else {
            this.appmixerStudioUrl = status.url.replace('api.', 'my.');
        }
    },
    methods: {
        async getComponentQueryIds(query, { limit, offset } = {}) {
            const stage = this.search.stage.value;
            const indexQuery = QueryUtils.getComponentUsageQuery(query, '$flowId', {
                ...(stage ? { stage } : {}),
                limit,
                offset
            });

            const result = await IndexQueries.queryComponentUsage(indexQuery);
            return result.map(r => r._id);
        },

        async getComponentCount(query) {
            const stage = this.search.stage.value;
            const indexQuery = QueryUtils.getComponentUsageQuery(query, '$flowId', {
                ...(stage ? { stage } : {}),
                count: true
            });

            const result = await IndexQueries.queryComponentUsage(indexQuery);
            return _.get(result, '[0].count', 0);
        },

        changedSearch(searchParameter) {
            const trimmedOldValue = _.trim(searchParameter.old);
            const trimmedNewValue = _.trim(searchParameter.current);

            if (trimmedNewValue !== trimmedOldValue) {
                searchParameter.old = searchParameter.current;
                this.refreshFlows();
            }
        },

        changedSearchString() {
            this.changedSearch(this.search.searchString);
        },

        changedComponentQuery() {
            this.changedSearch(this.search.componentQuery);
        },

        refreshFlows() {
            this.search.page = 1;
            this.getFlowsCount();
            this.getFlows();
        },

        async generateFlowQuery() {
            let result = {
                executeQuery: true,
                query: { filter: {} }
            };

            const { query } = result;

            const searchString = _.trim(this.search.searchString.current);
            if (searchString.length > 0) {
                switch (this.search.searchBy.value) {
                    case 'userId':
                        query.filter.userId = searchString;
                        result.executeQuery = /^[0-9a-fA-F]{24}$/.test(searchString);
                        break;
                    case 'flowId':
                        query.filter.flowId = searchString;
                        break;
                    case 'username':
                        const users = await UserRequests.queryUsers({
                            filter: {
                                username: searchString
                            }
                        });
                        if (users.length > 0) {
                            query.filter.userId = users[0].id;
                        } else {
                            result.executeQuery = false;
                        }
                        break;
                    default:
                        // flowName
                        query.pattern = searchString;
                }
            }
            query.filter.stage = this.search.stage.value;
            return result;
        },

        async getFlowsCount() {
            const searchString = this.search.searchString.current;
            if (this.search.searchBy.value === 'module' && searchString.length > 0) {
                this.total = await this.getComponentCount(searchString);
            } else {
                const { executeQuery, query } = await this.generateFlowQuery();
                const filter = {
                    query
                };
                if (!executeQuery) {
                    this.total = 0;
                } else {
                    const response = await this.fetchFlowsCount(filter);
                    this.total = response.count;
                }
            }
        },

        async getFlows() {
            this.search.loading = true;
            const searchString = this.search.searchString.current;
            if (this.search.searchBy.value === 'module' && searchString.length > 0) {
                const { limit, offset } = QueryUtils.paginationToLimitOffset(this.search.page, this.search.pageSize);
                try {
                    const flowIds = await this.getComponentQueryIds(searchString, { limit, offset });
                    if (flowIds.length > 0) {
                        const filter = {
                            relationships: ['user'],
                            query: {
                                filter: flowIds.length > 1 ? flowIds.map(f => `flowId:${f}`) : { flowId: flowIds[0] },
                                sort: { btime: 'desc' }
                            }
                        };
                        this.items = await this.fetchFlows(filter);
                    } else {
                        this.items = [];
                    }
                } finally {
                    this.search.loading = false;
                }
            } else {
                const { executeQuery, query } = await this.generateFlowQuery();
                const filter = {
                    relationships: ['user'],
                    query: {
                        ...query,
                        sort: { btime: 'desc' },
                        page: this.search.page,
                        pageSize: this.search.pageSize
                    }
                };
                try {
                    if (executeQuery) {
                        const result = await this.fetchFlows(filter);
                        this.items = this.processFlows(result);
                    } else {
                        this.items = [];
                    }
                } finally {
                    this.search.loading = false;
                }
            }
        },

        processFlows(flowsResult) {
            return flowsResult.reduce((acc, flow) => {
                if (validateFlow(flow)) {
                    acc.push(flow);
                }
                return acc;
            }, []);
        },

        showDescriptor(flow) {
            this.descriptor.show = true;
            this.descriptor.flow = flow;
        },
        showThumbnail(thumbnail) {
            this.thumbnail.show = true;
            this.thumbnail.src = thumbnail;
        },
        async showTelemetry(flow) {
            this.telemetry.show = true;
            this.telemetry.flowId = flow.flowId;
            this.telemetry.flowName = flow.name;

            this.telemetry.loading = true;
            try {
                this.telemetry.result = await this.fetchTelemetry(flow.flowId);
            } finally {
                this.telemetry.loading = false;
            }
        }
    }
};
</script>

<style scoped lang="scss">
.thumbnail {
    width: 90px
}

.action-btn:hover {
    text-decoration: underline;
}

.no-padding {
    padding-bottom: 0;
    padding-top: 0;
}

</style>
