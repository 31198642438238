<template>
    <v-dialog
        :value="show"
        class="am-add-user-dialog"
        width="500"
        @input="($event) => $emit('updateShow', $event)"
    >
        <v-card>
            <v-card-title class="am-add-user-dialog__title">
                Add New User
            </v-card-title>

            <v-card-text>
                <h3>Username:</h3>
                <v-text-field
                    v-model="username"
                    type="text"
                />
            </v-card-text>

            <v-card-text>
                <h3>Email:</h3>
                <v-text-field
                    v-model="email"
                    type="email"
                />
            </v-card-text>

            <v-card-text>
                <h3>Password:</h3>
                <v-text-field
                    v-model="password"
                    type="password"
                />
            </v-card-text>

            <v-card-actions class="am-add-user-dialog__buttons">
                <v-btn
                    color="primary"
                    text
                    @click.stop="emitSave"
                >
                    Save
                </v-btn>
                <v-btn
                    color="grey"
                    text
                    @click.stop="$emit('cancel')"
                >
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: 'AddUserDialog',
    model: {
        prop: 'show',
        event: 'updateShow'
    },
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showDialog: false,
            username: '',
            email: '',
            password: ''
        };
    },
    watch: {
        show(val) {
            this.showDialog = val;
        }
    },
    methods: {
        emitSave() {
            const payload = {
                username: this.username,
                email: this.email,
                password: this.password
            };
            this.$emit('save', payload);
        }
    }
};
</script>

<style lang="scss" scoped>

  .am-add-user-dialog {
    &__title {
      font-weight: bold;
    }
  }

</style>
