import UserRequests from '../../../utils/requests/user';
export default {
    data() {
        return {
            addDialog: {
                show: false
            }
        };
    },

    methods: {
        addUser() {
            this.addDialog.show = true;
        },

        async storeUser(payload) {
            try {
                await UserRequests.addUser(payload);
                this.getUsers();
            } catch (error) {
                const data = error?.response?.data;
                const errorMsg = data?.statusCode + ' ' + data?.error + ': ' + data?.message;
                alert(errorMsg);
            }
            this.addDialog.show = false;
        }
    }
};
